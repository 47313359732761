import { EmailIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    FormControl,
    FormLabel,
    SimpleGrid
} from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { FunctionComponent, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useHookForm } from '@/hooks/useHookForm';
import { useValidation } from '@/hooks/useValidation';

import { FormControlError } from '@/components/FormControlError/FormControlError';
import MemberNumberInput from '@/components/UI';
import { Button } from '@/components/UI/Buttons/Button';
import { PasswordInput } from '@/components/UI/Inputs/Password/PasswordInput';
import NavLink from '@/components/UI/Links/NavLink';

import RoutePath from '@/constants/route-path';
import { LoginForm as LoginFormModel } from '@/models/forms/LoginForm';
import { AppService } from '@/services';
import { onlyNumberValidation } from '@/validators';

export const LoginForm: FunctionComponent<object> = () => {
    const router = useRouter();
    const intl = useIntl();
    const instanceCountryCode = useMemo(() => AppService.getAppCountryCode(), []);
    const { formState, handleSubmit, register, resetField } = useHookForm<LoginFormModel>();
    const passwordPlaceholderText = intl.formatMessage({ id: 'enter-password' });
    const [formError, setFormError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [isAccountLockedError, setIsAccountLockedError] = useState(false);
    const [isAccountDisabledError, setIsAccountDisabledError] = useState(false);
    const contactEmail = AppService.getContactEmailAddress();
    const { getValidationKey } = useValidation();
    const { cartItemsUnlogged } = useShoppingCart();

    const usernameFormControl = register('memberNumber', {
        required: {
            value: true,
            message: 'field-is-required'
        },
        validate: (value) => onlyNumberValidation(value)
    });
    const passwordFormControl = register('password', {
        required: {
            value: true,
            message: 'field-is-required'
        }
    });

    const loginFormSubmit: SubmitHandler<LoginFormModel> = async (controls) => {
        const redirectURL = async () => {
            const redirectURL = router.query.redirectURL as string | undefined;

            if (cartItemsUnlogged.length > 0) {
                if (redirectURL) {
                    return router.push(`${RoutePath.AddToCart}?redirectURL=${redirectURL}`);
                }

                return router.push(`${RoutePath.AddToCart}?redirectURL=${RoutePath.Home}`);
            }

            if (redirectURL) {
                const filesPathPosition = redirectURL.indexOf(RoutePath.Files);
                const filename = redirectURL.substring(filesPathPosition);

                return router.push(RoutePath.Files, {
                    pathname: filename
                });
            }

            const redirectPath = router.query.redirect as string | undefined;

            if (!redirectPath || redirectPath === RoutePath.SignOut) {
                return router.push(RoutePath.Home);
            }

            return router.push(decodeURIComponent(redirectPath));
        };

        controls.memberNumber = `${instanceCountryCode}-${controls.memberNumber.trim()}`;
        setFormError('');
        setSubmitting(true);
        signIn('credentials', {
            ...controls,
            redirect: false
        })
            .then(async (response) => {
                setSubmitting(false);
                if (!response) {
                    return;
                }
                const { ok, error } = response;

                if (error) {
                    setIsAccountLockedError(() => error === 'account.locked');
                    setIsAccountDisabledError(() => error === 'account.disabled');
                    setFormError(() => error);
                    resetField('password');
                    return;
                }
                if (!ok) {
                    return;
                }

                await redirectURL();
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <form noValidate onSubmit={handleSubmit(loginFormSubmit)}>
            <Box>
                {formError && (
                    <Alert status="error" marginBottom={4} whiteSpace="pre-wrap">
                        <AlertIcon />
                        <AlertDescription>
                            <FormattedMessage id={getValidationKey(formError)} />
                            {isAccountDisabledError && (
                                <>
                                    {' '}
                                    <NavLink href={`mailto:${contactEmail}`} isExternal>
                                        {contactEmail}
                                    </NavLink>
                                    .
                                </>
                            )}
                            {isAccountLockedError && (
                                <Box>
                                    <EmailIcon fontSize="20px" mr={1} />
                                    <NavLink href={`mailto:${contactEmail}`} isExternal>
                                        {contactEmail}
                                    </NavLink>
                                </Box>
                            )}
                        </AlertDescription>
                    </Alert>
                )}
                <SimpleGrid
                    as="fieldset"
                    columns={{
                        base: 1,
                        sm: 2
                    }}
                    gap={4}
                >
                    <FormControl
                        isInvalid={!!formState.errors[usernameFormControl.name]}
                        isRequired
                        marginBottom={0}
                    >
                        <FormLabel>
                            <FormattedMessage id="login" />
                        </FormLabel>
                        <FormControlError control={usernameFormControl} formState={formState}>
                            <MemberNumberInput formControlRef={usernameFormControl} />
                        </FormControlError>
                    </FormControl>
                    <FormControl
                        isInvalid={!!formState.errors[passwordFormControl.name]}
                        isRequired
                        marginBottom={0}
                    >
                        <FormLabel>
                            <FormattedMessage id="password" />
                        </FormLabel>
                        <FormControlError control={passwordFormControl} formState={formState}>
                            <PasswordInput
                                inputProps={{
                                    autoComplete: 'current-password',
                                    placeholder: passwordPlaceholderText,
                                    ...passwordFormControl
                                }}
                            />
                        </FormControlError>
                    </FormControl>
                </SimpleGrid>
                <FormControl textAlign="right">
                    <NavLink
                        href={RoutePath.RemindPassword}
                        fontWeight="bold"
                        color="blue.500"
                        fontSize="sm"
                    >
                        <FormattedMessage id="forgot-password"></FormattedMessage>
                    </NavLink>
                </FormControl>
                <Box textAlign="center">
                    <FormControl marginBottom="2.5">
                        <Button
                            type="submit"
                            colorScheme="blue"
                            variant="outline"
                            width="250px"
                            isLoading={submitting}
                        >
                            <FormattedMessage id="log-in"></FormattedMessage>
                        </Button>
                    </FormControl>
                </Box>
            </Box>
        </form>
    );
};
